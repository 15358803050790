<template>
    <div class="p-new-persona">
        <div class="container-xl">
            <PageHeader title="New persona" text="Select an existing project:" goBack />
            <div class="row">
                <div v-for="project in projects" :key="project.name" class="col-md-4 col-6">
                    <Card :title="project.name" :text="`${personasCount(project.name)} persona(s)`" :to="`/${project.name}/choose-persona-type`" noThumbnail />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Card from '../components/Card.vue';
    import PageHeader from '../components/PageHeader.vue';

    export default {
        name: 'NewPersona',

        components: {
            Card,
            PageHeader,
        },

        computed: {
            ...mapGetters({
                personasCount: 'personas/countPersonaByProject',
                projectsFiles: 'projects/projects',
            }),
            personas() {
                return this.personasCount
            },
            projects() {
                return this.projectsFiles
            }
        },
    }
</script>
